<template>
  <v-card :disabled="isProcessing">
    <template v-slot:progress>
      <v-progress-linear
        absolute
        color="success"
        height="4"
        indeterminate
      ></v-progress-linear>
    </template>
    <v-toolbar
      color="#c31100"
      dark
    >
      <v-toolbar-title>{{ $t("PRICE_LISTS.LIST") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="hasPermissions('price-lists-create')"
        color="orange"
        @click="$router.push({ name: 'price-lists-create' })"
      >
        {{ $t('PRICE_LISTS.ADD') }}
      </v-btn>
    </v-toolbar>
    <v-card flat>
      <v-card-text>
        <Table
          v-if="userStatePrepared"
          ref="price-lists-table"
          :name="tableName"
          :headers="headers"
          :filters="filters"
          :server-data-options="{
            method: tableItemsMethod
          }"
          :loading="isProcessing"
          dense
          fixed-header
          multi-sort
          filterable
          :blink-row-item-key="blinkRowItemKey"
        >
          <template v-slot:item.options="{ item }">
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  x-small
                  color="indigo"
                  v-bind="attrs"
                  v-on="on"
                  @click="handleEdit(item.item.id)"
                >
                  fa fa-edit
                </v-icon>
              </template>
              <span>
                {{ $t('GENERAL.LABELS.OPEN') }}
              </span>
            </v-tooltip>
            <v-tooltip
              v-if="hasPermissions('price-lists-create')"
              top
              color="primary"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  x-small
                  color="indigo"
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                  @click="copyPriceList(item.item.id)"
                >
                  fa fa-copy
                </v-icon>
              </template>
              <span>
                {{ $t('GENERAL.LABELS.COPY') }}
              </span>
            </v-tooltip>
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  x-small
                  color="indigo"
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                  @click="openPrintView(item.item.id)"
                >
                  fa fa-print
                </v-icon>
              </template>
              <span>
                {{ $t('GENERAL.LABELS.PRINT_VIEW') }}
              </span>
            </v-tooltip>
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  x-small
                  color="indigo"
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                  @click="download(item.item.id, item.item.name)"
                >
                  fa fa-download
                </v-icon>
              </template>
              <span>
                {{ $t('GENERAL.LABELS.DOWNLOAD') }}
              </span>
            </v-tooltip>
            <v-tooltip
              top
              color="primary"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  x-small
                  color="indigo"
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                  @click="showContacts(item.item.id)"
                >
                  flaticon-users
                </v-icon>
              </template>
              <span>
                {{ $t('GENERAL.LABELS.SHOW_RELATED_CONTACTS') }}
              </span>
            </v-tooltip>
            <v-tooltip
              v-if="hasPermissions('price-lists-delete') && item.item.status_slug === 'draft'"
              top
              color="red"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  x-small
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  @click="handleDeleteClick(item.item.id)"
                >
                  fa fa-trash
                </v-icon>
              </template>
              <span>
                {{ $t('GENERAL.LABELS.DELETE') }}
              </span>
            </v-tooltip>
          </template>
        </Table>
      </v-card-text>
    </v-card>
    <HtmlEditor
      ref="html-preview-dialog"
      :hideButton="'true'"
      :getContent="getHtmlPreviewContent"
    />
    <ContactsDialog
      v-if="contactsDialogPriceListId"
      v-model="showContactsDialog"
      :server-data-options="{
        method: contactsTableItemsMethod,
        params: {
          priceListId: contactsDialogPriceListId
        }
      }"
      :selectable="false"
      @dialog-close="showContactsDialog = false"
    />
    <Confirm
      v-model="deleteConfirm"
      :content="$t('GENERAL.DELETE_CONFIRM')"
      @confirm="deletePriceList"
      @decline="deleteConfirm = false"
    />
  </v-card>
</template>

<script>
import { SHOW_SNACK } from "@/store/snack.module";
import Table from "@/views/partials/v-table/Table.vue";
import Confirm from "@/views/partials/dialogs/Confirm.vue";
import { GET_SELECTS_DATA } from "@/store/selectsData.module";
import userStateService from "@/common/userState.service.js";
import HtmlEditor from "@/views/partials/content/HtmlEditor.vue";
import ContactsDialog from "@/views/pages/priceLists/partials/ContactsDialog.vue";
import {
  GET_PRICE_LISTS,
  COPY_PRICE_LIST,
  GET_PRICE_LIST_PREVIEW_CONTENT,
  GET_PRICE_LIST_ALL_CONTACTS,
  DELETE_PRICE_LIST
} from "@/store/priceLists.module";

export default {
  components: {
    Table,
    Confirm,
    HtmlEditor,
    ContactsDialog
  },

  data() {
    return {
      tableName: "price-lists-table",
      blinkRowItemKey: null,
      userStatePrepared: false,
      htmlPreviewContent: '',
      tableItemsMethod: GET_PRICE_LISTS,
      contactsTableItemsMethod: GET_PRICE_LIST_ALL_CONTACTS,
      contactsDialogPriceListId: null,
      showContactsDialog: false,
      deleteConfirm: false,
      itemForDelete: null,
      headers: [
        {
          text: '#',
          value: 'id',
        },
        {
          text: this.$t('GENERAL.LABELS.NAME'),
          value: 'name',
          align: 'center'
        },
        {
          text: this.$t('GENERAL.LABELS.STATUS'),
          value: 'status',
          align: 'center'
        },
        {
          text: this.$t('GENERAL.LABELS.TYPE'),
          value: 'type',
          align: 'center'
        },
        {
          text: this.$t('GENERAL.LABELS.TEMPERATURE'),
          value: 'temp',
          align: 'center'
        },
        {
          text: this.$t('GENERAL.LABELS.CREATED_BY'),
          value: 'created_by',
          align: 'center'
        },
        {
          text: this.$t('GENERAL.LABELS.CREATED_DATE'),
          value: 'created_date',
          align: 'center'
        },
        {
          text: this.$t('GENERAL.LABELS.PUBLISHED_BY'),
          value: 'published_by',
          align: 'center'
        },
        {
          text: this.$t('GENERAL.LABELS.PUBLISHED_DATE'),
          value: 'published_date',
          align: 'center'
        },
        {
          text: this.$t('GENERAL.TABLE.OPTIONS_COLUMN'),
          value: 'options',
          sortable: false,
          width: '140px',
        },
      ],
      filters: [
        {
          key: 'name',
          label: this.$t('GENERAL.LABELS.NAME'),
          type: 'text'
        },
        {
          key: 'status',
          label: this.$t('GENERAL.LABELS.STATUS'),
          type: 'select',
          'item-value': 'key',
          'item-text': 'name',
          items: []
        },
        {
          key: 'type',
          label: this.$t('GENERAL.LABELS.TYPE'),
          type: 'select',
          'item-value': 'key',
          'item-text': 'name',
          items: []
        },
        {
          key: 'temp',
          label: this.$t('GENERAL.LABELS.TEMPERATURE'),
          type: 'select',
          'item-value': 'key',
          'item-text': 'name',
          items: []
        },
        {
          key: 'created_by',
          label: this.$t('GENERAL.LABELS.CREATED_BY'),
          type: 'select',
          'item-value': 'key',
          'item-text': 'name',
          items: []
        },
        {
          key: 'created_date',
          label: this.$t('GENERAL.LABELS.CREATED_DATE'),
          type: 'date'
        },
        {
          key: 'published_by',
          label: this.$t('GENERAL.LABELS.PUBLISHED_BY'),
          type: 'select',
          'item-value': 'key',
          'item-text': 'name',
          items: []
        },
        {
          key: 'published_date',
          label: this.$t('GENERAL.LABELS.PUBLISHED_DATE'),
          type: 'date'
        },
      ],
      isProcessing: false
    };
  },

  mounted() {
    this.fetchSelectsData();

    this.blinkRowItemKey = userStateService.getState(
      `table-state.${this.tableName}.blinkRowItemKey`,
      null
    );

    this.userStatePrepared = true;
  },

  methods: {
    fetchSelectsData() {
      return this.$store.dispatch(GET_SELECTS_DATA, [
        'users',
        'temps',
        'price-list-types',
        'price-list-statuses'
      ])
        .then((response) => {
          this.filters[1].items = response['price-list-statuses'];
          this.filters[2].items = response['price-list-types'];
          this.filters[3].items = response['temps'];
          this.filters[4].items = response['users'];
          this.filters[6].items = response['users'];
        }).catch(() => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleEdit(id) {
      userStateService.setState(
        `table-state.${this.tableName}.blinkRowItemKey`,
        id
      );

      this.$router.push({
        name: 'price-lists-edit',
        params: { id: id }
      });
    },

    copyPriceList(priceListId) {
      this.isProcessing = true;
      return this.$store.dispatch(COPY_PRICE_LIST, priceListId)
        .then(response => {
          userStateService.setState(
            `table-state.${this.tableName}.blinkRowItemKey`,
            response.id
          );

          this.$router.push({
            name: 'price-lists-edit',
            params: { id: response.id }
          });
        }).catch(() => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    openPrintView(priceListId) {
      this.isProcessing = true;

      this.$store.dispatch(GET_PRICE_LIST_PREVIEW_CONTENT, priceListId)
        .then(response => {
          this.htmlPreviewContent = response.content;

          this.$refs['html-preview-dialog'].showDialog();

          this.isProcessing = false;
        }).catch(() => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    getHtmlPreviewContent() {
      return this.htmlPreviewContent;
    },

    download(priceListId, priceListName) {
      this.isProcessing = true;

      this.$store.dispatch(GET_PRICE_LIST_PREVIEW_CONTENT, priceListId)
        .then(response => {
          var element = document.createElement("a");

          element.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," +
              encodeURIComponent(
                response.content
                  .replace(/<p><strong>/g, "")
                  .replace(/<\/strong><\/p>/g, "\n")
                  .replace(/<p>/g,"")
                  .replace(/<\/p>/g,"\n")
              )
          );

          element.setAttribute("download", priceListName + ".txt");

          element.style.display = "none";
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);

          this.isProcessing = false;
        }).catch(() => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    showContacts(priceListId) {
      this.contactsDialogPriceListId = priceListId;

      this.showContactsDialog = true;
    },

    handleDeleteClick(id) {
      this.itemForDelete = id;
      this.deleteConfirm = true;
    },

    deletePriceList() {
      this.isProcessing = true;

      this.$store.dispatch(DELETE_PRICE_LIST, this.itemForDelete)
        .then(() => {
          this.$refs['price-lists-table'].fetchServerData();

          this.isProcessing = false;
        }).catch(() => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
  //
</style>
